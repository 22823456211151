export default [

  // {
  //   title: 'Companies',
  //   route: 'companies',
  //   icon: 'BriefcaseIcon',
  //   action: 'browes',
  // },
  {
    title: ' Employees',
    route: 'employees',
    icon: 'UsersIcon',
    action: 'browes',
  },
  {
    title: 'Devices',
    route: 'devices',
    icon: 'UsersIcon',
    action: 'browes',
  },
]
